import get from 'lodash.get';

// === Data === //
import { softwarePageData } from '@graphql/SoftwarePage';

// === Helpers === //
import { getNodeEntity } from '@utils';
import { getDataFromPreview } from '@helpers/form';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { metatags } from '@DTO/sections/Metatags';
import { fullScreenImage } from '@DTO/sections/FullScreenImage';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { gotAQuestion } from '@DTO/sections/GotAQuestion';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { languagePath } from '@DTO/sections/LanguagePath';

export const SoftwarePageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const dataQuery = softwarePageData();
    currentPageData = dataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      fullScreenImage(nodeEntity, 'fieldFullScreenImage'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      gotAQuestion(nodeEntity, 'fieldCopyWithCta'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
