import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const softwarePageData = () => {
  const data = useStaticQuery(graphql`
    query softwarePageQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "software_page" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeSoftwarePage {
              entityLabel

              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldFullScreenImage {
                entity {
                  ...ParagraphFullScreenImage
                }
              }
              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldCopyWithCta {
                entity {
                  ...ParagraphCopyWithCta
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
