import get from 'lodash.get';
import { IGotAQuestion } from '@components/sections/GotAQuestion/GotAQuestion.d';
import { globalTranslations } from '@helpers/globalTranslations';

export const gotAQuestion = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IGotAQuestion = {
    type: get(data, 'entityBundle', ''),
    title: get(data, 'fieldHeadline.processed', ''),
    copy: get(data, 'fieldCopy.processed', ''),
    label: globalTranslations('fieldContactUs'),
    cta: get(data, 'fieldContactPage', undefined) ? {
      label: get(data, 'fieldContactPage.entity.title', ''),
      href: get(data, 'fieldContactPage.entity.path.alias', '/'),
      variant: 'outline'
    } : undefined
  };

  return mappedData;
};
